

.carousel {
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  transition: background-image 1s ease-in-out;
}

.event-info {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

