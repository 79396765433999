@import "./assets/plugins/bootstrap/css/bootstrap.min.css";

@import "./assets/css/main.css";

@import "./assets/css/theme2.css";

@import "./assets/css/custom.css";

@import "./assets/plugins/charts-c3/c3.min.css";

/* @import './assets/plugins/summernote/dist/summernote.css'; */

@import "./assets/plugins/jvectormap/jvectormap-2.0.3.css";

@import "./assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css";

@import "./assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";

@import "./assets/plugins/fullcalendar/fullcalendar.min.css";

@import "./assets/plugins/jquery-steps/jquery.steps.css";

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  font-family: "Geist", serif !important;
  background-color: white;
  /* padding: 1%; */
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Taglinefont{
  font-family: Marcellus !important;
}

.bg-orange {
  background-color: #ee7828 !important;
}
.bg-black {
  background-color: black !important;
}
.bg-graphite {
  background-color: #2c2c2c !important;
}
.bg-gray {
  background-color: #f7f7f7 !important;
}
.bg-concrete {
  background-color: #c5baaa !important;
}
.bg-craft {
  background-color: #806d61 !important;
}
.bg-white {
  background-color: white;
}
.bg-lightgray{
  background-color: #e8e7e7 !important;

}

.text-orange {
  color: #ee7828 !important;
}
.text-black {
  color: black !important;
}
.text-graphite {
  color: #2c2c2c !important;
}
.text-gray {
  color: #6c757d !important;
}
.text-concrete {
  color: #c5baaa !important;
}
.text-craft {
  color: #806d61 !important;
}
.text-white {
  color: white;
}
.main-red{
  color: rgb(227 27 35 / var(--tw-bg-opacity, 1)) !important;
}
.all-width{
  width: 100%; 
}
.all-height{
  height: 100%; 
}
.width-full{
  width:"100vh !important"
}
.d-flex {
  display: flex;
}

.bg-red{
  background-color: rgb(227 27 35 / var(--tw-bg-opacity, 1)) !important;
  color: white;
}

.form-check-input, .form-check-input
{
  width: 1.3rem !important
}

.custom-checkbox {
  width: 1.3rem !important;
}

form .form-check-input {
  width: 1.3rem !important;
}

form .form-select, form .form-control,.form-select
{
  padding: 6px 8px !important;

}

.width-50{
  width: 50%;
}

.accordion-button {
  box-shadow: none !important;
  outline: none !important;
  background-color: white !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  content: none !important; /* Removes the built-in arrow icon */
}


/* Override Bootstrap's container class */
.custom-container {
  max-width: 90% !important;
}


.remoteRow{
  display: flex;
  justify-content: start;
  align-items: center;
}

.innerRow{
  padding: 2px 7px;
  margin: 5px;
  font-weight: bold;
  background-color: #e5e5e5;
  border-radius: 25px;
  font-size: 13px;
}
.upperRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav-tabs .nav-link {
  padding: 5px 15px; 
  font-size: 14px; 
  height: 40px; 
  line-height: 30px;
} 
.btn-red{
  color:white !important;
  background-color: rgb(227 27 35 / var(--tw-bg-opacity, 1)) !important;
  transition: none !important;
}


.btn-red:hover {
  color: white; /* Ensure color stays white */
  background-color: #dc0a12; /* Keep background the same */
  box-shadow: none; /* Prevent shadow changes if applied */
  text-decoration: none; /* Avoid underline or text changes */
}

.border-gray{
  border: 1px solid #c3c3c380;
}

.font-xs{
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500
}

.font-xxs{

  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}


/* Aspect Ratio */
.aspect-ratio {
  aspect-ratio: 4 / 3;
}

/* Gradient Overlay */
.gradient-overlay {
  background: linear-gradient(to top, rgba(140, 140, 140, 0.6), transparent);
}

/* Hover Effect */
.group-hover-scale:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.custom-hover:hover {
  /* border-color:  #dc0a12 !important; */
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.form-check-input:checked{
  background-color: #dc0a12 !important;
    border-color: #dc0a12 !important;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* Limit text to 2 lines */
  -webkit-box-orient: vertical;  /* Ensure text is displayed in a vertical box */
  overflow: hidden;  /* Hide the overflowed text */
  text-overflow: ellipsis;  /* Show ellipsis (...) when text overflows */
}


.redTextOnhover{
  color: gray;
}

.redTextOnhover:hover{
  color:  #dc0a12 !important;

}

.redborderOnhover{
  border-color: gray;
}

.redborderOnhover:hover{
  border-color:  #ff8589 !important;

}

.main-card {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;}

.main-card:hover{
  background-color: rgb(245, 244, 244);
  transform: translateY(-2px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.custom-checkbox {
  width: 16px;
  height: 16px;
  background-color: white;
  border: 2px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: black;
  border-color: black;
}